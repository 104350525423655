<script>
import { Card, Button, Checkbox } from "@/components";
import Client from "@/services/Client";
import VLoader from "@/components/VLoader";

export default {
    name: "IGallery",
    bodyClass: "blog-post",
    components: {
        Card,
        VLoader,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        albums() {
            return [
                {
                    title: this.$t("album.living-room"),
                    path: "/docsgallery/living-room",
                },
                {
                    title: this.$t("album.bedrooms"),
                    path: "/docsgallery/bedrooms",
                },
                {
                    title: this.$t("album.kitchen"),
                    path: "/docsgallery/kitchen",
                },
                {
                    title: this.$t("album.bathroom"),
                    path: "/docsgallery/bathroom",
                },
                {
                    title: this.$t("album.outdoor"),
                    path: "/docsgallery/outdoor",
                },
            ];
        },
    },
    async created() {},
    methods: {},
};
</script>

<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax
                class="page-header-image"
                style="background-image: url('/img/bgs/papug.jpg')"
            />
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">
                            {{ $t("albums") }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <router-link
                                :to="`/docs/toc/${$i18n.locale}`"
                                class="btn btn-primary btn-round btn-lg"
                            >
                                <i class="now-ui-icons text_align-left" />
                                {{ $t("back_to_home_toc") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ml-auto mr-auto">
                            <v-loader v-if="loading" />
                            <template v-else-if="albums.length > 0">
                                <p class="text-center mb-5">
                                    {{ $t("select_inside_album") }}
                                </p>

                                <div class="row ">
                                    <div
                                        v-for="(album, index) in albums"
                                        :key="index"
                                        class="text-center col-12 col-md-3 album mb-3"
                                    >
                                        <router-link :to="album.path">
                                            <i
                                                class="far fa-images fa-2x mr-2"
                                            ></i>
                                            <span class="album__title">{{
                                                album.title
                                            }}</span>
                                        </router-link>
                                    </div>
                                </div>

                                <div>
                                    <router-view name="photo_tab"></router-view>
                                </div>

                                <!-- https://gist.github.com/WebCu/e3e3f58d9aa6f10c68f33f586726c5cc
                                Klucze do mieszkania:
                                https://github.com/defuse/php-encryption/blob/master/docs/classes/KeyProtectedByPassword.md -->
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.album {
    &:hover {
        cursor: pointer;
    }

    .router-link-exact-active {
        padding-bottom: 4px;
        border-bottom: 3px dotted #f96332;
    }

    &__title {
        font-size: 24px;
    }
}
</style>
