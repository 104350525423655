<script>
import { Card, Button, Checkbox } from "@/components";
import Client from "@/services/Client";
import VLoader from "@/components/VLoader";

export default {
    name: "VGallery",
    bodyClass: "blog-post",
    props: {
        lang: {},
        doc: {},
    },
    components: {
        Card,
        VLoader,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
    },
    data() {
        return {
            loading: true,
            document: null,
            albums: [],
        };
    },
    async created() {
        this.fetchAlbums();
        this.loading = false;
    },
    methods: {
        async fetchAlbums() {
            try {
                const r = await Client.GET("/albums/albums.json");
                this.albums = r.data;
            } catch (e) {}
        },
    },
};
</script>

<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax
                class="page-header-image"
                style="background-image: url('/img/bgs/papug.jpg')"
            />
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">
                            {{ $t("albums") }}
                        </h2>

                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <router-link
                                to="/"
                                class="btn btn-primary btn-round btn-lg"
                            >
                                <i class="fas fa-home" />
                                {{ $t("back_to_home") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ml-auto mr-auto">
                            <v-loader v-if="loading" />
                            <template v-else-if="albums.length > 0">
                                <p class="text-center mb-5">
                                    {{ $t("select_album") }}
                                </p>

                                <div class="row text-center">
                                    <div
                                        v-for="(album, index) in albums"
                                        :key="index"
                                        class="col-12 col-md-3 album"
                                    >
                                        <router-link :to="album.path">
                                            <i
                                                class="far fa-images fa-2x mr-2"
                                            ></i>
                                            <span class="album__title">{{
                                                album.title
                                            }}</span>
                                        </router-link>
                                    </div>
                                </div>

                                <div>
                                    <router-view name="photo_tab"></router-view>
                                </div>

                                <!-- https://gist.github.com/WebCu/e3e3f58d9aa6f10c68f33f586726c5cc
                                Klucze do mieszkania:
                                https://github.com/defuse/php-encryption/blob/master/docs/classes/KeyProtectedByPassword.md -->
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" v-if="false">
                <div class="container">
                    <div class="col-md-12">
                        <h2 class="title text-center">Similar Stories</h2>
                        <br />
                        <div id="blogs-1" class="blogs-1">
                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <card type="blog" plain>
                                        <template slot="raw-content">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="card-image">
                                                        <img
                                                            class="img img-raised rounded"
                                                            src="img/examples/card-blog4.jpg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-7">
                                                    <h6
                                                        class="category text-info"
                                                    >
                                                        Enterprise
                                                    </h6>
                                                    <h3 class="card-title">
                                                        <a href="#pablo"
                                                            >Warner Music Group
                                                            buys concert
                                                            discovery service
                                                            Songkick</a
                                                        >
                                                    </h3>
                                                    <p class="card-description">
                                                        Warner Music Group
                                                        announced today it’s
                                                        acquiring the selected
                                                        assets of the music
                                                        platform Songkick,
                                                        including its app for
                                                        finding concerts and the
                                                        company’s trademark.
                                                    </p>
                                                    <p class="author">
                                                        by
                                                        <a href="#pablo">
                                                            <b
                                                                >Sarah Perez</b
                                                            > </a
                                                        >, 2 days ago
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </card>
                                    <card type="blog" plain>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <h6
                                                    class="category text-danger"
                                                >
                                                    <i
                                                        class="now-ui-icons now-ui-icons media-2_sound-wave"
                                                    />
                                                    Startup
                                                </h6>
                                                <h3 class="card-title">
                                                    <a href="#pablo"
                                                        >Insticator raises $5.2M
                                                        to help publishers</a
                                                    >
                                                </h3>
                                                <p class="card-description">
                                                    Insticator is announcing
                                                    that it has raised $5.2
                                                    million in Series A funding.
                                                    The startup allows online
                                                    publishers to add quizzes,
                                                    polls and other interactive
                                                    elements...
                                                </p>
                                                <p class="author">
                                                    by
                                                    <a href="#pablo">
                                                        <b>Anthony Ha</b> </a
                                                    >, 5 days ago
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-image">
                                                    <img
                                                        class="img img-raised rounded"
                                                        src="img/examples/card-blog6.jpg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.album {
    &:hover {
        cursor: pointer;
    }

    .router-link-exact-active {
        padding-bottom: 4px;
        border-bottom: 3px dotted #f96332;
    }

    &__title {
        font-size: 24px;
    }
}
</style>
