<script>
import { Card, Button, Checkbox } from "@/components";
import Client from "@/services/Client";
import VLoader from "@/components/VLoader";
import VCalendar from "v-calendar/lib/components/calendar.umd";

export default {
    name: "VBCalendar",
    bodyClass: "blog-post",
    props: {
        lang: {},
        doc: {},
    },
    components: {
        Card,
        VLoader,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        VCalendar,
    },
    data() {
        return {
            loading: true,
            dates: [],
        };
    },
    computed: {
        attrs() {
            if (this.dates.length === 0) {
                return [];
            }

            let atts = [];

            this.dates.forEach((v) => {
                console.log(v);
                atts.push({
                    highlight: {
                        start: { fillMode: "outline" },
                        base: { fillMode: "light" },
                        end: { fillMode: "outline" },
                    },
                    dates: {
                        start: new Date(v.from),
                        end: new Date(v.to),
                    },
                });
            });

            return atts;
        },
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await Client.GET("/api/calendar");
                this.dates = data.data;
            } catch (e) {}

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax
                class="page-header-image"
                style="background-image: url('/img/bgs/papug.jpg')"
            />
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">
                            {{ $t("calendar") }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <router-link
                                :to="{
                                    name: 'doc',
                                    params: { lang: 'toc', doc: $i18n.locale },
                                }"
                                class="btn btn-primary btn-round btn-lg"
                            >
                                <i class="now-ui-icons text_align-left" />
                                {{ $t("docs.back_to_table_of_contents") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ml-auto mr-auto">
                            <v-loader v-if="false" />
                            <template>
                                <p class="text-center mb-5">
                                    {{ $t("check_availability_dates_text") }}
                                </p>

                                <v-calendar
                                    :rows="$screens({ lg: 2 }, 1)"
                                    :columns="$screens({ lg: 3 }, 1)"
                                    :is-expanded="
                                        $screens({ default: true, lg: true })
                                    "
                                    :attributes="attrs"
                                    :locale="$i18n.locale"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" v-if="false">
                <div class="container">
                    <div class="col-md-12">
                        <h2 class="title text-center">Similar Stories</h2>
                        <br />
                        <div id="blogs-1" class="blogs-1">
                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <card type="blog" plain>
                                        <template slot="raw-content">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="card-image">
                                                        <img
                                                            class="img img-raised rounded"
                                                            src="img/examples/card-blog4.jpg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-7">
                                                    <h6
                                                        class="category text-info"
                                                    >
                                                        Enterprise
                                                    </h6>
                                                    <h3 class="card-title">
                                                        <a href="#pablo"
                                                            >Warner Music Group
                                                            buys concert
                                                            discovery service
                                                            Songkick</a
                                                        >
                                                    </h3>
                                                    <p class="card-description">
                                                        Warner Music Group
                                                        announced today it’s
                                                        acquiring the selected
                                                        assets of the music
                                                        platform Songkick,
                                                        including its app for
                                                        finding concerts and the
                                                        company’s trademark.
                                                    </p>
                                                    <p class="author">
                                                        by
                                                        <a href="#pablo">
                                                            <b
                                                                >Sarah Perez</b
                                                            > </a
                                                        >, 2 days ago
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </card>
                                    <card type="blog" plain>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <h6
                                                    class="category text-danger"
                                                >
                                                    <i
                                                        class="now-ui-icons now-ui-icons media-2_sound-wave"
                                                    />
                                                    Startup
                                                </h6>
                                                <h3 class="card-title">
                                                    <a href="#pablo"
                                                        >Insticator raises $5.2M
                                                        to help publishers</a
                                                    >
                                                </h3>
                                                <p class="card-description">
                                                    Insticator is announcing
                                                    that it has raised $5.2
                                                    million in Series A funding.
                                                    The startup allows online
                                                    publishers to add quizzes,
                                                    polls and other interactive
                                                    elements...
                                                </p>
                                                <p class="author">
                                                    by
                                                    <a href="#pablo">
                                                        <b>Anthony Ha</b> </a
                                                    >, 5 days ago
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-image">
                                                    <img
                                                        class="img img-raised rounded"
                                                        src="img/examples/card-blog6.jpg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.album {
    &:hover {
        cursor: pointer;
    }

    .router-link-exact-active {
        padding-bottom: 4px;
        border-bottom: 3px dotted #f96332;
    }

    &__title {
        font-size: 24px;
    }
}
</style>
